<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader'
import { GoogleMap, Marker } from 'vue3-google-map'
import type { BlockContent, Theme, GoogleMaps } from '~/types'

interface GoogleMapProps {
  readable?: boolean;
  content: BlockContent;
  location: GoogleMaps;
  theme: Theme;
}

const runtimeConfig = useRuntimeConfig()
const props = defineProps<GoogleMapProps>()
const center = { ...props.location }

type GoogleType = typeof window.google
let loader, apiPromise: Promise<GoogleType> | null
if (process.browser) {
  loader = new Loader({
    apiKey: runtimeConfig.public.googleMapsApiKey,
    version: 'weekly'
  })
  apiPromise = !window?.google ? loader.load() : Promise.resolve(window.google)
}
</script>

<template>
  <ClientOnly>
    <div v-if="apiPromise" :class="['map', themeStyles(theme)]">
      <div :class="['max-wrapper relative flex flex-col-reverse flex-wrap gap-8 md:flex-row md:flex-nowrap', { readable }]">
        <div class="map-con w-full">
          <GoogleMap
            :api-promise="apiPromise"
            style="width: 100%; height: 400px"
            :center="center"
            :zoom="15"
            :options="{
              center,
              disableDefaultUI: false
            }"
          >
            <Marker :options="{ position: center }" />
          </GoogleMap>
        </div>
        <Card v-if="content" card-template="summary" :theme="theme" class="w-full">
          <BlockContent :content="content" />
        </Card>
      </div>
    </div>
  </ClientOnly>
</template>
